.main {

  height: 100vh;
  position: relative;
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    // padding-top: 1.9em;

    font-family: 'Cera Round Pro Black';
    font-style: normal;
    font-weight: 700;
    font-size: 2.4em;
    line-height: 1.3em;
    text-align: center;
    letter-spacing: 0.05rem;

    background: linear-gradient(
      180deg,
      #ffc74f 60%,
      #ffe866 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-transform: uppercase;

    z-index: 99;
  }

  .description {
    margin: 0 auto;
    max-width: 700px;
    font-family: 'Cera Round Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.2em;
    text-align: center;

    color: #ffffff;

    text-shadow: 0px 0.1em 0.1em rgba(0, 0, 0, 0.25);
    z-index: 99;
  }

  .content {
    // border: solid 1px green;
    // padding-left: 1em;
    .itemsCont {
      margin: 4.2em 4.4em 0;

      display: flex;
    }

    .note {
      padding-top: 5em;

      width: 70%;
      margin: 0 auto;
      max-width: 700px;
      font-family: 'Cera Round Pro Black';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5em;
      line-height: 1.2em;
      text-align: center;

      color: #ffffff;

      text-shadow: 0px 0.1em 0.1em rgba(0, 0, 0, 0.25);
      z-index: 99;
    }

    .bttn {
      margin-top: 3.2em;
      // margin-bottom: 50vh;
      display: inline-block;
    }
  }

}