.main {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url(../../resources/images/footer/bg.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .content {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    display: flex;

    .logoCont {
      width: 36%;
      // padding-left: 1em;
      .logo {
        // width: 80%;
        width: 28.5em;
      }

      .copyright {
        font-family: 'Cera Round Pro';
        color: white;
        font-size: 1.1em;
      }
    }

    .infoCont {
      position: relative;
      width: 64%;
      // padding-left: .5em;
      .infoCont_row1 {
        display: flex;
        // align-items: center;
        justify-content: flex-start;

        .navPanel {
          // width: 60%;
        }
        
        .socialLinkPanel {
          position: absolute;
          top: 1.7em;
          right: 9em;
        }
      }
      .infoCont_row2 {
        margin: 3em 0;
        .hr1 {
          width: 80%;
          border: 0;
          height: 2px;
          background-image: linear-gradient(to right, transparent, #CCC, transparent); 
        }
      }
      .infoCont_row3 {
        display: flex;
        padding-top: 0.6em;
        padding-left: 1.1em;
      }
    }

  }

}