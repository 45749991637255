@import '../mixines.scss';

.main {
  width: 100vw;
  height: 100vh;
  background-color: #37456c;
}

// video::-internal-media-controls-download-button {
//   display:none;
// }

// video::-webkit-media-controls-enclosure {
//   overflow:hidden;
// }

// video::-webkit-media-controls-panel {
//   width: calc(100% + 30px); /* Adjust as needed */
// }

.videoTag {
  height: 100vh;
  width: 100vw;

  object-fit: cover;

  position: absolute;
  bottom: 0;
  right: 0;
  content: "";

  &.poster {
    width: 100vw;
    height: 100vh;
  }
}

.info {
  width: 41em;
  position: absolute;
  z-index: 3;

  /* border: solid 1px red; */

  top: 10.8%;
  left: 4.2%;

  .logoIcon {
    // border: solid 1px red;
    width: 41.2em;
  }

  .bttns {
    margin-top: 4.1em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info__description {

    width: 86%;
    padding-top: 1.85em;
    padding-left: 2.6em;
    text-align: left;
  
    font-family: 'Cera Round Pro';
    font-style: normal;
    font-weight:normal;
    font-size: 1.5em;
    line-height: 1.2em;
    letter-spacing: -0.001rem;
  
    color: #ffffff;

    @include mainTextShadow;
  }

  .blablaBlock {
    width: 80%;
    margin-left: 2.5em;
    margin-top: 2.6em;
  }

  .blablaItem {
    font-family: 'Cera Round Pro';
    font-style: normal;
    font-weight:normal;
    font-size: 2.4em;
    line-height: 1.15em;
    letter-spacing: -0.02rem;
    color: white;

    @include mainTextShadow;
  }
}

.main > h1 {
  margin: 0;
  padding: 0;
}

.info__totalplayers {
  width: 60%;
  color: white;

  background: rgba($color: #000000, $alpha: 0.25);
  /* opacity: 0.59; */
  border-radius: 5px;

  margin: 3.7em 7em 0;
}

.info__totalplayers_title {

  font-family: 'Cera Round Pro Black';
  font-style: normal;
  font-weight: 700;
  font-size: 1.8em;
  line-height: 1em;
  letter-spacing: 0.06rem;
  text-transform: uppercase;

  background: white;
  // background: linear-gradient(180deg, #58d5fd -37.5%, #00c2ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  padding-top: 0.5em;

  @include mainFilterShadow;
}

.info__totalplayers_count {
  margin: 0 auto;

  font-family: 'Cera Round Pro Black';
  font-style: normal;
  font-weight: 700;
  font-size: 3.2em;
  line-height: 1em;
  letter-spacing: -0.09rem;
  padding-top: 0.14em;
  padding-bottom: 0.16em;

  text-align: center;

  color: #F9A437;
  // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @include mainTextShadow;
}

.info__contracts {

  font-family: 'Cera Round Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 1.1em;
  line-height: 1.1em;
  letter-spacing: -0.074rem;

  color: #ffffff;

  mix-blend-mode: normal;

  margin-top: 5.2em;
  margin-left: 4px;
}

.info__contracts_title {
  text-align: left;
  letter-spacing: -0.02rem;
}

.info__contracts_line {
  letter-spacing: -0.046rem;

  display: flex;
  justify-content: flex-start;

  margin: 0px 0 5px 0;

  text-align: center;
}

.thc {
    padding-top: 0.2em;
}

.info__contracts_key {
  background-color: rgba(2, 174, 188, 0.4);
  margin-right: 8px;

  border-radius: 3px;

  padding: 0.1em 0.3em;
}

.info__contracts_val {
  border-radius: 3px;
  padding: 0.1em 0.5em;
  background-color: rgba(2, 174, 188, 0.4);
}
