.main {
  display: inline-flex;
  width: 30.55em;
  height: 33.3em;

  filter: drop-shadow(0 0 4em #f2b87c);

  margin: 0.5em -3.7em 0;
  // border: solid 1px red;
}

.img {
  height: 13.6em;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // border: solid 1px green;

  z-index: 9;
}

.bgCont {
  // border: solid 1px yellow;
  
  // height: 4em;

  position: absolute;
  top: 0;
  left: 0;

  .bg {
    height: 33em;
    animation: bgAnimation 70s linear infinite;
    // animation-fill-mode: forwards;

    @keyframes bgAnimation {
        0% {
          transform: scale(0.7) rotate(0deg);
          // opacity: 0.8;
        }
        50% {
          transform: scale(1) rotate(-180deg);
          // opacity: 1;
        }
        100% {
            transform: scale(0.7) rotate(-360deg);
            // opacity: 0.8;
        }
    }
  }
}

.blink {
  height: 5em;

  position: absolute;
  top: 0;
  left: 0;

  z-index: 8;

  animation: blinkAnimation 10s linear infinite;
  animation-fill-mode: forwards;

  @keyframes blinkAnimation {
      0% {
        transform: scale(0.4) rotate(0deg);
        opacity: 0.6;
      }
      50% {
        transform: scale(1) rotate(180deg);
        opacity: 1;
      }
      100% {
          transform: scale(0.4) rotate(360deg);
          opacity: 0.6;
      }
  }

  &.a0{
    animation-delay: 12s;
    top: 11.4em;
    left: 3.7em;
  }

  &.a1{
    animation-delay: 10s;
    top: 7.7em;
    left: 6.1em;
  }

  &.a2{
    animation-delay: 13s;
    top: 21.8em;
    left: 6.2em;
  }

  &.a3{
    animation-delay: 11s;
    top: 8.9em;
    left: 19.6em;
  }

  &.a4{
    animation-delay: 15s;
    top: 20em;
    left: 19.6em;
  }

  &.a5{
    animation-delay: 17s;
    top: 23.3em;
    left: 18em;
  }

  // @for $i from 1 through 10 {
  //     &.a#{$i}{
  //         $animationdelay: random($i);
  //         animation-delay: #{$animationdelay}s;
  //         top: #{$animationdelay}em;
  //         left: #{$animationdelay}em;
  //     }
  // }
}

// .video {
//   z-index: -1;
//   /* width: 400px; */

//   width: 35em;
//   /* margin: 0 -1.2em; */

//   /* width: 42em; */
//   /* margin: 0 -4.2em; */

//   -webkit-mask-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0) 70%);
//   mask-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 70%);
// }