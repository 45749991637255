.main > a {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.main > a > img {
  width: 3.5em;
  margin: 0.4em 0.6em;
}
