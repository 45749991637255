@import '../mixines.scss';

.main {
  position: relative;
  
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100vw;
  height: 100vh;
  background: url(../../resources/images/oasis_coin/bg.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .content {
    position: absolute;
    top: 40%; // 41.5%
    left: 50%;
    transform: translate(-50%, -50%);

    .title {
      font-family: 'Cera Round Pro Black';
      font-style: normal;
      font-weight: 700;
      font-size: 7.2em;
      line-height: 1.3em;
      text-align: center;
    
      background: linear-gradient(
        180deg,
        #fdbb2b 70%,
        #ffe866 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-transform: uppercase;

      @include mainFilterShadow;
    
      z-index: 99;
    }
    
    .description {
      margin: 0 auto;
      max-width: 700px;
      font-family: 'Cera Round Pro Black';
      font-style: normal;
      font-weight: 600;
      font-size: 2em;
      line-height: 1.2em;
      text-align: center;
      letter-spacing: 0.07rem;
    
      color: #ffffff;

      @include mainTextShadow;
    
      z-index: 99;
    }
  }

  .footer {
    width: 100vw;
    padding: 0 0 4em 0;

    position: fixed;
    left: 0;
    bottom: 0;

    .hr1 {
      width: 80%;
      border: 0;
      height: 2px;
      background-image: linear-gradient(to right, transparent, #CCC, transparent);

      margin: 2.6em auto;
    }

    .footerCont {
      display: flex;
      justify-content: space-around;
  
      .logoCont {
        padding-top: 0.4em;
        padding-left: 0.8em;
        .logo { width: 24.3em; }
      }

      .linkCont {
        display: flex;
        padding-top: 1em;
        padding-right: 10.3em;

        .plinkcont {
          margin: 0 1em 0 0;
        }
  
        .glinkcont {
        }
      }

      .socialLinkPanel {
        padding-top: 1.5em;
        padding-right: 1.7em;
      }
      
    }
  }
  
}