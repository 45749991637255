.main {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);

  .content {
    display: flex;
    justify-content: center;
    align-items: center;

    .leftArrow {
      height: 26.8em;
    }
    .rightArrow {
      height: 27.8em;
    }
  }

  .bttn {
    display: inline-block;
    margin: 0 auto 0;
  }
}