.main {
  width: 186px;
  height: 197px;
  
  position: absolute;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
  
  background: url('../../../resources/images/loader/70.png') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  background-position: 0 0;
  animation: bug-moov 2s steps(20) infinite;
}

@keyframes bug-moov {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -3720px 0;
  }
}