$progress_width: 444px; // 29em;
$progress_height: 14px; // 0.9em;
$border_radius: $progress_height/2; // 0.45em;

.main {
  position: relative;
  
  display: flex;
}

.progress__line {
  display: flex;
}

.progress__bg {
  width: $progress_width;
  height: $progress_height;

  background: #5a5a6c;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: $border_radius;
}

.progress__bar {
  width: 0%;
  height: $progress_height;

  background: linear-gradient(110deg, #26cbfd, #26cbfd)  !important;
  box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.25);
  border-radius: $border_radius;
}