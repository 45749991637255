.main {
  position: relative;
  
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100vh;
  background: url(../../resources/images/roadmap/bg.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  -webkit-mask: linear-gradient(
    transparent,
    #fff 0px calc(100% - 1px),
    transparent
  );
  mask: linear-gradient(transparent, #fff 0px calc(100% - 1px), transparent);

  .slider {
    width: 96%;
    // display: flex;
    // justify-content: center;
  }

  
  .title {
    /* margin-top: 1em; */
    padding-top: 1.9em;

    font-family: 'Cera Round Pro Black';
    font-style: normal;
    font-weight: 700;
    font-size: 2.4em;
    line-height: 1.3em;
    text-align: center;
    letter-spacing: 0.05rem;

    background: linear-gradient(
      180deg,
      #ffc74f 60%,
      #ffe866 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-transform: uppercase;

    // background: linear-gradient(
    //   180deg,
    //   #cbf3ff -37.5%,
    //   #daf6fe 88.97%,
    //   rgba(228, 248, 254, 0.455208) 99.99%,
    //   #58d5fd 100%
    // );
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-clip: text;

    /* text-shadow: 0px 0.2em 0.2em rgba(0, 0, 0, 0.25); */
    z-index: 99;
  }

  .description {
    margin: 0 auto;
    max-width: 700px;
    font-family: 'Cera Round Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4em;
    line-height: 1.2em;
    text-align: center;

    color: #ffffff;

    text-shadow: 0px 0.1em 0.1em rgba(0, 0, 0, 0.25);
    z-index: 99;
  }

  .tobeText {
    position: absolute;
    top: 6.5em;
    right: 1.8em;

    font-family: 'Cera Round Pro Black';
    font-style: normal;
    font-weight: 700;
    font-size: 1.9em;
    line-height: 1em;
    text-align: center;
    letter-spacing: 0.05rem;

    background: linear-gradient(
    180deg,
    #ffc74f 60%,
    #ffe866 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .tobeText.text1 {
    top: 6.5em;
    right: 3em;
  }

  .decor1 {
    height: 28.7em;

    position: absolute;
    top: -6em;
    left: 8.4em;
  }

  .decor2 {

    height: 16.9em;

    position: absolute;
    top: 0;
    right: -6em;

  }

  .decor3 {
    height: 14.7em;

    position: absolute;
    top: 18.7em;
    right: 4.6em;
  }

  .decor4 {
    height: 4.5em;

    position: absolute;
    top: 13em;
    right: .6em;
  }

}

.ftSlickDotsCustom {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-family: 'Cera Round Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 1.4em;
  line-height: 1em;
  text-align: center;
  
  color: #fff;
  padding: 0.2em 0.16em;
  margin: 0 0.2em;

  cursor: pointer;
}