@import '../mixines.scss';

.main {
  width: 100vw;

  height: 100vh;
  min-height: 540px;

  .info {
    // position: relative;

    width: 100vw;
    display: flex;
    flex-direction: column;
    padding-top: calc(72px + 30px);
    z-index: 3;

    .logoImg {
      width: 90%;
      margin: 6em auto 1em;
    }

    .info > video {
      margin-top: 3em;
      width: 100vw;
    
      object-fit: cover;
    }
    
    .info__title {
      border: 1px solid red;
      font-family: 'Cera Round Pro Black';
      font-style: normal;
      font-weight: normal;
      font-size: 19em;
      line-height: 0.95em;
      letter-spacing: 0.3rem;
      text-align: left;
    
      color: #f0f0f0;
    
      padding-left: 0.4em;
    
      @include mainTextShadow
    }
    
    .info__description {
      width: 80%;
    
      margin: 1em auto;
      text-align: left;
    
      font-family: 'Cera Round Pro';
      font-style: normal;
      font-weight: normal;
      font-size: 5em;
      line-height: 1.15em;
      letter-spacing: 0.04rem;
      text-align: center;
    
      color: #ffffff;
    
      @include mainTextShadow
    }
    
    .info__description_tags {
      font-size: 0.6em;
      font-weight: 700;
      letter-spacing: -0.005rem;
      margin-top: -0.2em;
      padding: 1em 0.16em;
      color: #1296B6;
    }

    .info__totalplayers {
      width: 70%;
      margin: 1em auto;

      background: rgba($color: #000000, $alpha: 0.25);
      border-radius: 5px;
    }
    
    .info__totalplayers_title {
    
      font-family: 'Cera Round Pro Black';
      font-style: normal;
      font-size: 5em;
      line-height: 1em;
      letter-spacing: 0.06rem;
      text-transform: uppercase;
    
      background: white;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    
      padding-top: 0.5em;
    
      @include mainFilterShadow;
    }
    
    .info__totalplayers_count {
      margin: 0 auto;
    
      font-family: 'Cera Round Pro Black';
      font-style: normal;
      font-weight: 700;
      font-size: 9em;
      line-height: 1em;
      letter-spacing: -0.09rem;
      padding-top: 0.14em;
      padding-bottom: 0.16em;
    
      text-align: center;
    
      color: #F9A437;
      // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      @include mainTextShadow;
    }
  }
}

.tradeNowBttn:hover {
  filter: brightness(1.1);
}
.tradeNowBttn:active {
  filter: brightness(0.9);
}

.traderNowbBttnTitle {
  font-family: 'Cera Round Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 5em;
  line-height: 1.1em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.01rem;

  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  padding: 1em 0;
}

.heroImg {
  width: 100vw;
  margin-top: -19em;

  @include retina(3) {
    margin-top: -6em;
  }
}