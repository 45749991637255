.panel {
  position: fixed;
  top: 45%;
  right: 0;
  width: 53px;
  // background-color: rgba(17, 110, 124, 0.6);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px 0 0 6px;
  padding: 6px 0 6px 2px;
  z-index: 10;
}

.panel > a {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.panel > a > img {
  width: 35px;
  margin: 8px;
}

.panel > a > img:hover {
  transition: all 0.2s;
  transform: scale(1.1);
}

/* @media screen and (max-width: 920px) {.panel {font-size: 1em !important;}} */
@media screen and (max-width: 768px) {
    .panel {
        width: 45px;
    }
  
    .panel > a > img {
      width: 35px;
      margin: 4px;
    }
  }
  /* @media screen and (max-width: 426px) {.panel {font-size: 1em !important;}}
  @media screen and (max-width: 425px) {.panel {font-size: 1em !important;}}
  @media screen and (max-width: 375px) {.panel {font-size: 1em !important;}}
  @media screen and (max-width: 325px) {.panel {font-size: 1em !important;}}
  @media screen and (max-width: 280px) {.panel {font-size: 1em !important;}} */