@import '../../mixines.scss';

.main {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  font-family: 'Cera Round Pro Black';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2em;
  line-height: 1em;
  letter-spacing: -0.04rem;
  text-align: center;

  color: #ffffff;

  @include mainTextShadow;
}

.items {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 0 0 10px;
  padding: 8px 0 8px 2px;
  z-index: 10;
}
