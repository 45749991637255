@import '../../mixines.scss';

// @media screen and (max-width: 920px) {.nav__item {font-size: 2em !important;}}
// @media screen and (max-width: 768px) {.nav__item {font-size: 3.5em !important;}}
// @media screen and (max-width: 426px) {.nav__item {font-size: 4.5em !important;}}
// @media screen and (max-width: 425px) {.nav__item {font-size: 4.5em !important;}}
// @media screen and (max-width: 375px) {.nav__item {font-size: 4.5em !important;}}
// @media screen and (max-width: 325px) {.nav__item {font-size: 4.5em !important;}}
// @media screen and (max-width: 280px) {.nav__item {font-size: 6.5em !important;}}


.main {
    padding: 0 1em;
    .content {
        display: inline-block;
        .title {
            color: white;
            font-family: 'Cera Round Pro';
            text-align: left;
            font-size: 1.12em;

            padding: 0 0 1em 0;

            @include mainTextShadow;
        }
        .itemCont {
            border: solid 1px green;
        }
    }
}