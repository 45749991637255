.main {
  background: rgba($color: #000000, $alpha: 0.4);
  border-radius: 0.4em;
  padding: 0.5em 1em;

  font-size: 0.7em;
  line-height: 1em;
  // letter-spacing: -0.01rem;
  color: white;
  font-family: 'Cera Round Pro';
  text-align: left;

  margin: 0 6px;
}
.main.inLine {
  /* display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto; */
}

.contentWrapper{}
.contentWrapper.inLine{
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
}

.info__contracts_title{}
.info__contracts_title.inLine{
  padding-right: 10px;
}

.info__contracts_items {
  display: flex;
}

@media screen and (max-width: 768px) {
    .main {
        margin: 0.3em 0;
    }
}