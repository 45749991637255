.intro {
    width: 100vw;
    height: 100vh;
    background-color: #71ACCE;
}

.intro>video {
    height: 100vh;
    width: 100vw;

    object-fit: cover;
}

.intro>h1 {
    margin: 0;
    padding: 0;
}