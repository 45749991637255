.main {
    // border: 1px solid red;
    margin: 0 3em;
    .content {
        position: relative;

        .title {
            font-family: 'Cera Round Pro Black';
            font-style: normal;
            font-weight: 700;
            font-size: 1.95em;
            line-height: 1em;
            text-align: left;
            letter-spacing: 0.05rem;

            background: linear-gradient(
            180deg,
            #ffc74f 50%,
            #ffe866 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-transform: uppercase;

            // padding-left: 1em;
            padding-bottom: 0.5em;
        }

        .desc {
            font-family: 'Cera Round Pro Black';
            font-style: normal;
            font-weight: 700;
            font-size: 1.4em;
            line-height: 1em;
            text-align: left;
            letter-spacing: 0.05rem;

            background: linear-gradient(
            180deg,
            #ffc74f 50%,
            #ffe866 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;

            // padding-left: 1em;

            padding-bottom: 6.6em;
        }


        .tobeText {
            // height: 10em;
            width: 9em;

            position: absolute;
            top: -2.8em;
            left: 5.7em;

            font-family: 'Cera Round Pro Black';
            font-style: normal;
            font-weight: 700;
            font-size: 1.9em;
            line-height: 1em;
            text-align: right;
            letter-spacing: 0.05rem;

            background: linear-gradient(
            180deg,
            #ffc74f 60%,
            #ffe866 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }

        .decor {
            height: 10em;

            position: absolute;
            top: -2.5em;
            left: 18em;
        }
        .decor1Cont {
            height: 7em;

            position: absolute;
            top: 4.8em;
            left: -3em;

            .decor1 {
                height: 7em;
            }
        }
        .decor2 {
            height: 2.65em;

            position: absolute;
            top: 12.6em;
            left: -1.25em;
        }
        .decor3 {
            height: 2.65em;

            position: absolute;
            top: 8.9em;
            left: 3.6em;
        }
        .decor4 {
            height: 2.65em;

            position: absolute;
            top: 6.7em;
            left: 9.85em;
        }
        .decor5 {
            height: 2.65em;

            position: absolute;
            top: 6.9em;
            left: 16.8em;
        }

        .decor6 {
            height: 5em;

            position: absolute;
            top: -3em;
            left: 6.8em;

            // animation: 1s ease-out 0s 1 slideInFromLeft;
            animation: slideInFromLeft 10s linear infinite;
            animation-fill-mode: forwards;

            @keyframes slideInFromLeft {
                0% {
                  transform: scale(0.4) rotate(0deg);
                  opacity: 0.4;
                }
                50% {
                  transform: scale(1) rotate(180deg);
                  opacity: 1;
                }
                100% {
                    transform: scale(0.4) rotate(360deg);
                    opacity: 0.4;
                }
            }

            @for $i from 1 through 5 {
                &.a#{$i}{
                    $animationdelay: random(10);
                    animation-delay: #{$animationdelay}s;
                }
            }
        }

        .decor99 {
            height: 5em;

            position: absolute;
            top: -8em;
            left: 26em;

            animation: starRotate 10s linear infinite;
            @keyframes starRotate {
                0% {
                  transform: scale(0.4) rotate(0deg);
                //   opacity: 0.4;
                }
                50% {
                  transform: scale(1) rotate(180deg);
                //   opacity: 1;
                }
                100% {
                    transform: scale(0.4) rotate(360deg);
                    // opacity: 0.4;
                }
            }
        }

        .card {
            width: 17.1em;

            background: rgba(51, 50, 81, 0.4);
            box-shadow: 0px 0px 20px 0.1px #60baff, 0px 0px 10px 0.3px #73c9ff inset;
            border: 3px solid #bef5ff;
            border-radius: 0.5em;
        }
    }
}