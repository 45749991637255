.main {
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 99;
}

.main.left {
  /* justify-content: end; */
}

.main.right {
  /* justify-content: start; */
}

.title {
  font-family: 'Cera Round Pro Black';
  font-style: normal;
  font-weight: 400;
  font-size: 3.35em;
  line-height: 1em;
  letter-spacing: -0.04rem;
  text-align: center;

  color: rgba(8, 236, 251, 0.6);

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: rotate(-89.8deg) scale(-1);
}
.title.left {
    transform: rotate(-89.8deg);
}
.title.right {
    transform: rotate(-89.8deg) scale(-1);
    margin-left: -1.1em;
}
.info {
  display: flex;
  flex-direction: column;
}
.info.left {
  align-items: flex-end;
}
.info.right {
  align-items: flex-start;
}
