.main {
  margin: 0 0px 0 0;
}

.info__contracts_line {
  /* height: 1.2em; */
  /* height: 2em; */

  display: flex;
  justify-content: flex-start;
  /* justify-content: space-between; */
  margin-right: 0em;

  text-align: center;

  /* font-size: 1em;
  color: white;
  font-family: Staatliches;
  line-height: 1em; */

  font-size: 1.1em;
  color: white;
  font-family: 'Cera Round Pro';
  line-height: 1em;
  letter-spacing: 0.013rem;
}

.info__contracts_key {
  display: flex;
  justify-content: center;
  align-items: center;

  // background-color:rgba(2, 174, 188, 0.4);
  // border-radius: 3px;

  margin-right: 0.4em;
  padding: 4px 0px 0px;
}

.info__contracts_val {
  display: flex;
  justify-content: center;
  align-items: center;
  
  padding: 4px 0px 0px;
  // background-color:rgba(2, 174, 188, 0.4);
  // border-radius: 3px;
}

.copyBttn {
  // border: 1px solid red;
  width: 14px;
  margin: 0px 0px 0px 4px;
  padding-left: 4px;
  padding-bottom: 2px;
  // filter: invert(100%) sepia(100%) saturate(10000%) hue-rotate(180deg);

  cursor: pointer;
}