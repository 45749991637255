@import '../../mixines.scss';

.main {
  padding: 0.2em 1em;
}
.bttn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0px;
  font-size: 1.3em;

  // box-shadow: 0px 6px 6px rgba(0, 0, 0, 1);
  clip-path: polygon(0% 0, 100% 0, 100% 20%, 97% 30%, 100% 40%, 100% 100%, 0% 100%, 0 75%);
  border-radius: 0.25em;
  background: radial-gradient(
    123.41% 70.85% at 47.63% 49.99%,
    #09b6fe 40%,
    #2acefd 100%
  ) !important;
  cursor: pointer;
  height: 2em !important;
  padding: 1.1em 1.5em;
  transition: 0.3s;
}

.bttn:hover {
  filter: brightness(1.15);
}

.bttn:active {
  filter: brightness(0.8);
}

.title {

  font-family: 'Cera Round Pro';
  font-style: normal;

  text-decoration: none;
  font-weight: bold;
  font-size: 1.125em;
  line-height: 1.33;
  color: #fff;

  @include mainTextShadow;
}

.bttnWrap {
  @include mainFilterShadow;
}

.link {
  text-decoration: none;
}