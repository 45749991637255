.main {
    position: absolute;
    top: 0;
    width: 100%;
    height: 80px;
    z-index: 10;
}

.nav {
    /* height: 4em;
    background: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: center;
    align-items: center;

    height: 4.6em;
    background: rgba(27, 19, 22, 0.35);
    // background: rgba(33, 37, 78, 0.2);
}

.navSecondaty {
    
    opacity: 0;
    transition: 0.5s;
}

.navSecondaty:hover {
    opacity: 1 !important;
}

.logo {
    position: absolute;
    top: 1em;
    left: 2.5em;
}

.logoimg {
    width: 7em;
}

.nav__menu {
    // position: absolute;
    // top: 0.8em;
    // right: 2em;
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
}

.nav__item {
    margin-right: 26px;

    font-weight: bold;
    font-size: 2em;
}

.navlink {
    white-space: nowrap;

    text-decoration: none;
    color: white;
}

.navlink:hover {
    color: #F9A437;
    -webkit-text-stroke: 0.5px rgba(0, 0, 0, 0.6);
}

.marketplaceBttn {
    margin: 0 0 0 2em;
}