
@media screen and (max-width: 920px) {.nav__item {font-size: 2em !important;}}
@media screen and (max-width: 768px) {.nav__item {font-size: 3.5em !important;}}
@media screen and (max-width: 426px) {.nav__item {font-size: 4.5em !important;}}
@media screen and (max-width: 425px) {.nav__item {font-size: 4.5em !important;}}
@media screen and (max-width: 375px) {.nav__item {font-size: 4.5em !important;}}
@media screen and (max-width: 325px) {.nav__item {font-size: 4.5em !important;}}
@media screen and (max-width: 280px) {.nav__item {font-size: 6.5em !important;}}

.nav__item {
  margin: 1.2em 1.6em;

  font-family: 'Cera Round Pro Black';
  font-style: normal;
  font-weight: 100;
  font-size: 1.1em;
  line-height: 1em;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  text-transform: uppercase;

  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  /* что бы текст не выделялся */
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  /* text-transform: uppercase; */
}

.navlink {
  white-space: nowrap;

  text-decoration: none;
  color: white;

  padding: 0.2em 0.4em;
}

.navlink:hover {
  cursor: pointer;

  color: #f9a437;
  /* -webkit-text-stroke: 0.5px rgba(0, 0, 0, 0.6); */
}

.navLinkActive {
  border: 1px solid white;
  border-radius: 5px;

  /* border-bottom: 1.5px solid #fff; */
}
.navLinkActive:hover {
  border: 1px solid #f9a437;
  /* border-bottom: 1.5px solid #f9a437; */
}
