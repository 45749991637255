html {
  overflow: hidden;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
  position: fixed;
  overflow-y: scroll;

  /* overflow: hidden; */
  background-color: #5ec2ff;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 1024px) {
  html {overflow: auto;}
  body {
    position: relative;
    overflow-y: scroll;
    background-color: hsl(225, 33%, 44%);
  }
}