.main {
  .content {
    margin: 0.75em 0;
    display: flex;
    .arrow {
        height: 1.1em;
        padding: 0 0.6em 0 1em;
    }
    .skill {
        font-family: 'Cera Round Pro';
        font-size: 1.2em;
        line-height: 1em;
        text-align: left;
        // letter-spacing: -0.07rem;
        font-weight: normal;
        color: #fff;
    }
  }
}