@import '../../mixines.scss';

@media screen and (max-width: 920px) {.nav__item {font-size: 2em !important;}}
@media screen and (max-width: 768px) {.nav__item {font-size: 3.5em !important;}}
@media screen and (max-width: 426px) {.nav__item {font-size: 4.5em !important;}}
@media screen and (max-width: 425px) {.nav__item {font-size: 4.5em !important;}}
@media screen and (max-width: 375px) {.nav__item {font-size: 4.5em !important;}}
@media screen and (max-width: 325px) {.nav__item {font-size: 4.5em !important;}}
@media screen and (max-width: 280px) {.nav__item {font-size: 6.5em !important;}}

.nav__item {
  margin-right: 0.52em;

  /* font-family: SquadaOne; */
  font-family: 'Cera Round Pro';
  font-style: normal;
  font-weight: 100;
  font-size: 1.3em;
  line-height: 1em;
  // letter-spacing: -0.03rem;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: #ffffff;

  @include mainTextShadow; // text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);

  /* что бы текст не выделялся */
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.navlink {
  white-space: nowrap;

  text-decoration: none;
  color: white;

  padding: 0.3em 0.44em;
}

.navlink:hover {
  cursor: pointer;

  color: #f9a437;
  /* -webkit-text-stroke: 0.5px rgba(0, 0, 0, 0.6); */
}

.navLinkActive {
  border: 2px solid white;
  border-radius: 5px;
  background: #54a2d2;
  /* border-bottom: 1.5px solid #fff; */
}
.navLinkActive:hover {
  border: 2px solid #f9a437;
  /* border-bottom: 1.5px solid #f9a437; */
}
