.main {
    display: flex;
    justify-content: center;
}

.motion__cont {
    width: 90vw;
    height: 4em;

    position: fixed;
    bottom: -0px;
    background-color: rgba(0,0,0,0);
    /* background-color: rgba(17,110,124,.6); */
    display: flex;
    font-size: 1.1em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

@media screen and (max-width: 768px) {
    .main {
        align-items: center;
        font-size: 2.7em;
    }

    .motion__cont {
        flex-direction: column;
        height: 6em;

        bottom: 1em;
    }
}

@media screen and (max-width: 650px) {
    .motion__cont {
        flex-direction: column;
        height: 6em;
    }
}