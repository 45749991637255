
@font-face {
  font-family: 'Cera Round Pro';
  src: url('./resources/fonts/Souses\ Bold.woff') format('woff'),
  url('./resources/fonts/Souses\ Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Cera Round Pro Black';
  src: url('./resources/fonts/Souses\ Black.woff') format('woff'),
  url('./resources/fonts/Souses\ Black.woff2') format('woff2');
}

.App {
  text-align: center;
  background-color: black;
  overflow: hidden;
}

.App.mob {
  background-color: hsl(225, 42%, 40%);
  background: url(./resources/images/main/mob_bg.jpg) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@media screen and (max-width: 4096px) {.App {font-size: 2.1em;}}
@media screen and (max-width: 4096px) and (max-height: 2080px) {.App {font-size: 2em;}}
@media screen and (max-width: 4096px) and (max-height: 2000px) {.App {font-size: 1.9em;}}
@media screen and (max-width: 4096px) and (max-height: 1920px) {.App {font-size: 1.8em;}}
@media screen and (max-width: 4096px) and (max-height: 1800px) {.App {font-size: 1.7em;}}
@media screen and (max-width: 4096px) and (max-height: 1720px) {.App {font-size: 1.6em;}}
@media screen and (max-width: 4096px) and (max-height: 1620px) {.App {font-size: 1.5em;}}
@media screen and (max-width: 4096px) and (max-height: 1520px) {.App {font-size: 1.4em;}}
@media screen and (max-width: 4096px) and (max-height: 1420px) {.App {font-size: 1.3em;}}
@media screen and (max-width: 4096px) and (max-height: 1300px) {.App {font-size: 1.2em;}}
@media screen and (max-width: 4096px) and (max-height: 1200px) {.App {font-size: 1.1em;}}
@media screen and (max-width: 4096px) and (max-height: 1100px) {.App {font-size: 1em;}}
@media screen and (max-width: 4096px) and (max-height: 960px) {.App {font-size: .9em;}}
@media screen and (max-width: 4096px) and (max-height: 940px) {.App {font-size: .88em;}}
@media screen and (max-width: 4096px) and (max-height: 920px) {.App {font-size: .86em;}}
@media screen and (max-width: 4096px) and (max-height: 810px) {.App {font-size: .8em;}}
@media screen and (max-width: 4096px) and (max-height: 790px) {.App {font-size: .79em;}}
@media screen and (max-width: 4096px) and (max-height: 730px) {.App {font-size: .76em;}}
@media screen and (max-width: 4096px) and (max-height: 690px) {.App {font-size: .66em;}}
@media screen and (max-width: 4096px) and (max-height: 670px) {.App {font-size: .62em;}}
@media screen and (max-width: 4096px) and (max-height: 620px) {.App {font-size: .58em;}}
@media screen and (max-width: 4096px) and (max-height: 600px) {.App {font-size: .56em;}}
@media screen and (max-width: 4096px) and (max-height: 570px) {.App {font-size: .53em;}}
@media screen and (max-width: 4096px) and (max-height: 550px) {.App {font-size: .51em;}}
@media screen and (max-width: 4096px) and (max-height: 525px) {.App {font-size: .5em;}}
@media screen and (max-width: 4096px) and (max-height: 510px) {.App {font-size: .48em;}}
@media screen and (max-width: 4096px) and (max-height: 500px) {.App {font-size: .46em;}}
@media screen and (max-width: 4096px) and (max-height: 490px) {.App {font-size: .44em;}}
@media screen and (max-width: 4096px) and (max-height: 480px) {.App {font-size: .42em;}}
@media screen and (max-width: 4096px) and (max-height: 470px) {.App {font-size: .4em;}}
@media screen and (max-width: 4096px) and (max-height: 440px) {.App {font-size: .38em;}}
@media screen and (max-width: 4096px) and (max-height: 410px) {.App {font-size: .36em;}}

@media screen and (max-width: 3200px) {.App {font-size: 1em;}}
@media screen and (max-width: 3200px) and (max-height: 970px) {.App {font-size: .96em;}}
@media screen and (max-width: 3200px) and (max-height: 930px) {.App {font-size: .92em;}}
@media screen and (max-width: 3200px) and (max-height: 920px) {.App {font-size: .86em;}}
@media screen and (max-width: 3200px) and (max-height: 810px) {.App {font-size: .8em;}}
@media screen and (max-width: 3200px) and (max-height: 790px) {.App {font-size: .79em;}}
@media screen and (max-width: 3200px) and (max-height: 730px) {.App {font-size: .75em;}}
@media screen and (max-width: 3200px) and (max-height: 690px) {.App {font-size: .66em;}}
@media screen and (max-width: 3200px) and (max-height: 670px) {.App {font-size: .62em;}}
@media screen and (max-width: 3200px) and (max-height: 620px) {.App {font-size: .58em;}}
@media screen and (max-width: 3200px) and (max-height: 600px) {.App {font-size: .56em;}}
@media screen and (max-width: 3200px) and (max-height: 570px) {.App {font-size: .53em;}}
@media screen and (max-width: 3200px) and (max-height: 550px) {.App {font-size: .51em;}}
@media screen and (max-width: 3200px) and (max-height: 525px) {.App {font-size: .5em;}}
@media screen and (max-width: 3200px) and (max-height: 510px) {.App {font-size: .48em;}}
@media screen and (max-width: 3200px) and (max-height: 500px) {.App {font-size: .46em;}}
@media screen and (max-width: 3200px) and (max-height: 490px) {.App {font-size: .44em;}}
@media screen and (max-width: 3200px) and (max-height: 480px) {.App {font-size: .42em;}}
@media screen and (max-width: 3200px) and (max-height: 470px) {.App {font-size: .4em;}}
@media screen and (max-width: 3200px) and (max-height: 440px) {.App {font-size: .38em;}}
@media screen and (max-width: 3200px) and (max-height: 410px) {.App {font-size: .36em;}}

@media screen and (max-width: 1601px) {.App {font-size: .9em;}}
@media screen and (max-width: 1601px) and (max-height: 980px) {.App {font-size: .85em;}}
@media screen and (max-width: 1601px) and (max-height: 910px) {.App {font-size: .8em;}}
@media screen and (max-width: 1601px) and (max-height: 810px) {.App {font-size: .77em;}}
@media screen and (max-width: 1601px) and (max-height: 790px) {.App {font-size: .76em;}}
@media screen and (max-width: 1601px) and (max-height: 730px) {.App {font-size: .73em;}}
@media screen and (max-width: 1601px) and (max-height: 690px) {.App {font-size: .66em;}}
@media screen and (max-width: 1601px) and (max-height: 670px) {.App {font-size: .62em;}}
@media screen and (max-width: 1601px) and (max-height: 620px) {.App {font-size: .58em;}}
@media screen and (max-width: 1601px) and (max-height: 600px) {.App {font-size: .56em;}}
@media screen and (max-width: 1601px) and (max-height: 570px) {.App {font-size: .53em;}}
@media screen and (max-width: 1601px) and (max-height: 550px) {.App {font-size: .51em;}}
@media screen and (max-width: 1601px) and (max-height: 525px) {.App {font-size: .5em;}}
@media screen and (max-width: 1601px) and (max-height: 510px) {.App {font-size: .48em;}}
@media screen and (max-width: 1601px) and (max-height: 500px) {.App {font-size: .46em;}}
@media screen and (max-width: 1601px) and (max-height: 490px) {.App {font-size: .44em;}}
@media screen and (max-width: 1601px) and (max-height: 480px) {.App {font-size: .42em;}}
@media screen and (max-width: 1601px) and (max-height: 470px) {.App {font-size: .4em;}}
@media screen and (max-width: 1601px) and (max-height: 440px) {.App {font-size: .38em;}}
@media screen and (max-width: 1601px) and (max-height: 410px) {.App {font-size: .36em;}}

@media screen and (max-width: 1536px) {.App {font-size: .8em;}}
@media screen and (max-width: 1536px) and (max-height: 910px) {.App {font-size: .8em;}}
@media screen and (max-width: 1536px) and (max-height: 810px) {.App {font-size: .77em;}}
@media screen and (max-width: 1536px) and (max-height: 790px) {.App {font-size: .76em;}}
@media screen and (max-width: 1536px) and (max-height: 730px) {.App {font-size: .73em;}}
@media screen and (max-width: 1536px) and (max-height: 690px) {.App {font-size: .66em;}}
@media screen and (max-width: 1536px) and (max-height: 670px) {.App {font-size: .62em;}}
@media screen and (max-width: 1536px) and (max-height: 620px) {.App {font-size: .58em;}}
@media screen and (max-width: 1536px) and (max-height: 600px) {.App {font-size: .56em;}}
@media screen and (max-width: 1536px) and (max-height: 570px) {.App {font-size: .53em;}}
@media screen and (max-width: 1536px) and (max-height: 550px) {.App {font-size: .51em;}}
@media screen and (max-width: 1536px) and (max-height: 525px) {.App {font-size: .5em;}}
@media screen and (max-width: 1536px) and (max-height: 510px) {.App {font-size: .48em;}}
@media screen and (max-width: 1536px) and (max-height: 500px) {.App {font-size: .46em;}}
@media screen and (max-width: 1536px) and (max-height: 490px) {.App {font-size: .44em;}}
@media screen and (max-width: 1536px) and (max-height: 480px) {.App {font-size: .42em;}}
@media screen and (max-width: 1536px) and (max-height: 470px) {.App {font-size: .4em;}}
@media screen and (max-width: 1536px) and (max-height: 440px) {.App {font-size: .38em;}}
@media screen and (max-width: 1536px) and (max-height: 410px) {.App {font-size: .36em;}}

@media screen and (max-width: 1441px) {.App {font-size: .73em;}}
@media screen and (max-width: 1441px) and (max-height: 720px) {.App {font-size: .72em;}}
@media screen and (max-width: 1441px) and (max-height: 690px) {.App {font-size: .68em;}}
@media screen and (max-width: 1441px) and (max-height: 670px) {.App {font-size: .66em;}}
@media screen and (max-width: 1441px) and (max-height: 630px) {.App {font-size: .63em;}}
@media screen and (max-width: 1441px) and (max-height: 600px) {.App {font-size: .6em;}}
@media screen and (max-width: 1441px) and (max-height: 570px) {.App {font-size: .57em;}}
@media screen and (max-width: 1441px) and (max-height: 550px) {.App {font-size: .54em;}}
@media screen and (max-width: 1441px) and (max-height: 525px) {.App {font-size: .52em;}}
@media screen and (max-width: 1441px) and (max-height: 510px) {.App {font-size: .5em;}}
@media screen and (max-width: 1441px) and (max-height: 500px) {.App {font-size: .48em;}}
@media screen and (max-width: 1441px) and (max-height: 490px) {.App {font-size: .47em;}}
@media screen and (max-width: 1441px) and (max-height: 480px) {.App {font-size: .45em;}}
@media screen and (max-width: 1441px) and (max-height: 470px) {.App {font-size: .44em;}}
@media screen and (max-width: 1441px) and (max-height: 440px) {.App {font-size: .42em;}}
@media screen and (max-width: 1441px) and (max-height: 410px) {.App {font-size: .4em;}}

@media screen and (max-width: 1366px) {.App {font-size: .68em;}}
@media screen and (max-width: 1366px) and (max-height: 670px) {.App {font-size: .64em;}}
@media screen and (max-width: 1366px) and (max-height: 620px) {.App {font-size: .6em;}}
@media screen and (max-width: 1366px) and (max-height: 600px) {.App {font-size: .58em;}}
@media screen and (max-width: 1366px) and (max-height: 570px) {.App {font-size: .56em;}}
@media screen and (max-width: 1366px) and (max-height: 550px) {.App {font-size: .54em;}}
@media screen and (max-width: 1366px) and (max-height: 525px) {.App {font-size: .52em;}}
@media screen and (max-width: 1366px) and (max-height: 510px) {.App {font-size: .5em;}}
@media screen and (max-width: 1366px) and (max-height: 500px) {.App {font-size: .48em;}}
@media screen and (max-width: 1366px) and (max-height: 490px) {.App {font-size: .46em;}}
@media screen and (max-width: 1366px) and (max-height: 480px) {.App {font-size: .45em;}}
@media screen and (max-width: 1366px) and (max-height: 470px) {.App {font-size: .44em;}}
@media screen and (max-width: 1366px) and (max-height: 440px) {.App {font-size: .43em;}}
@media screen and (max-width: 1366px) and (max-height: 415px) {.App {font-size: .4em;}}

@media screen and (max-width: 1280px) {.App {font-size: .64em;}}
@media screen and (max-width: 1280px) and (max-height: 620px) {.App {font-size: .62em;}}
@media screen and (max-width: 1280px) and (max-height: 600px) {.App {font-size: .6em;}}
@media screen and (max-width: 1280px) and (max-height: 575px) {.App {font-size: .58em;}}
@media screen and (max-width: 1280px) and (max-height: 550px) {.App {font-size: .56em;}}
@media screen and (max-width: 1280px) and (max-height: 530px) {.App {font-size: .54em;}}
@media screen and (max-width: 1280px) and (max-height: 515px) {.App {font-size: .52em;}}
@media screen and (max-width: 1280px) and (max-height: 500px) {.App {font-size: .5em;}}
@media screen and (max-width: 1280px) and (max-height: 490px) {.App {font-size: .48em;}}
@media screen and (max-width: 1280px) and (max-height: 470px) {.App {font-size: .46em;}}
@media screen and (max-width: 1280px) and (max-height: 450px) {.App {font-size: .44em;}}
@media screen and (max-width: 1280px) and (max-height: 425px) {.App {font-size: .42em;}}
@media screen and (max-width: 1280px) and (max-height: 410px) {.App {font-size: .4em;}}

@media screen and (max-width: 1200px) {.App {font-size: .59em;}}
@media screen and (max-width: 1200px) and (max-height: 600px) {.App {font-size: .58em;}}
@media screen and (max-width: 1200px) and (max-height: 560px) {.App {font-size: .55em;}}
@media screen and (max-width: 1200px) and (max-height: 530px) {.App {font-size: .52em;}}
@media screen and (max-width: 1200px) and (max-height: 510px) {.App {font-size: .51em;}}
@media screen and (max-width: 1200px) and (max-height: 500px) {.App {font-size: .5em;}}
@media screen and (max-width: 1200px) and (max-height: 490px) {.App {font-size: .49em;}}
@media screen and (max-width: 1200px) and (max-height: 480px) {.App {font-size: .48em;}}
@media screen and (max-width: 1200px) and (max-height: 470px) {.App {font-size: .47em;}}
@media screen and (max-width: 1200px) and (max-height: 450px) {.App {font-size: .44em;}}
@media screen and (max-width: 1200px) and (max-height: 420px) {.App {font-size: .42em;}}
@media screen and (max-width: 1200px) and (max-height: 410px) {.App {font-size: .4em;}}

@media screen and (max-width: 1100px) {.App {font-size: .55em;}}
@media screen and (max-width: 1100px) and (max-height: 670px) {.App {font-size: .54em;}}
@media screen and (max-width: 1100px) and (max-height: 620px) {.App {font-size: .53em;}}
@media screen and (max-width: 1100px) and (max-height: 525px) {.App {font-size: .52em;}}
@media screen and (max-width: 1100px) and (max-height: 510px) {.App {font-size: .51em;}}
@media screen and (max-width: 1100px) and (max-height: 500px) {.App {font-size: .5em;}}
@media screen and (max-width: 1100px) and (max-height: 490px) {.App {font-size: .49em;}}
@media screen and (max-width: 1100px) and (max-height: 480px) {.App {font-size: .48em;}}
@media screen and (max-width: 1100px) and (max-height: 470px) {.App {font-size: .45em;}}
@media screen and (max-width: 1100px) and (max-height: 440px) {.App {font-size: .43em;}}
@media screen and (max-width: 1100px) and (max-height: 420px) {.App {font-size: .42em;}}
@media screen and (max-width: 1100px) and (max-height: 410px) {.App {font-size: .41em;}}

@media screen and (max-width: 1024px) {
  .App {
    font-size: .55em;
    overflow: auto;
  }
}
@media screen and (max-width: 1024px) and (max-height: 670px) {.App {font-size: .54em;}}
@media screen and (max-width: 1024px) and (max-height: 620px) {.App {font-size: .53em;}}
@media screen and (max-width: 1024px) and (max-height: 525px) {.App {font-size: .52em;}}
@media screen and (max-width: 1024px) and (max-height: 510px) {.App {font-size: .51em;}}
@media screen and (max-width: 1024px) and (max-height: 500px) {.App {font-size: .5em;}}
@media screen and (max-width: 1024px) and (max-height: 490px) {.App {font-size: .49em;}}
@media screen and (max-width: 1024px) and (max-height: 480px) {.App {font-size: .48em;}}
@media screen and (max-width: 1024px) and (max-height: 470px) {.App {font-size: .45em;}}
@media screen and (max-width: 1024px) and (max-height: 440px) {.App {font-size: .43em;}}
@media screen and (max-width: 1024px) and (max-height: 420px) {.App {font-size: .42em;}}
@media screen and (max-width: 1024px) and (max-height: 410px) {.App {font-size: .41em;}}

@media screen and (max-width: 768px) {.App {font-size: .35em;}}

@media screen and (max-width: 768px) {.App {font-size: .35em;}}
@media screen and (max-width: 460px) {.App {font-size: .3em;}}
@media screen and (max-width: 390px) {.App {font-size: .29em;}}
@media screen and (max-width: 375px) {.App {font-size: .26em;}}
@media screen and (max-width: 340px) {.App {font-size: .25em;}}
@media screen and (max-width: 325px) {.App {font-size: .23em;}}
@media screen and (max-width: 300px) {.App {font-size: .20em;}}

/* @media screen and (max-height: 700px) {.App {font-size: .7em;}}
@media screen and (max-height: 600px) {.App {font-size: .6em;}}
@media screen and (max-height: 500px) {.App {font-size: .5em;}} */

/* ring loader */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 4em;
  height: 4em;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 3.1em;
  height: 3.1em;
  margin: 0.4em;
  border: 0.4em solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* end ring loader */



/* mobile menu */
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  /* background: #373a47; */
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
/* end mobile menu */