@import '../../mixines.scss';

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin-top: 6.6em;

    // border: solid 1px green;

    >div {
        // margin: 2em -12em 2em 1em;
    }

    .submit__bttn {
        border: 0;
        // border: solid 1px greenyellow;
        
        background: url('../../../resources/images/comingsoon/sendBttn.png') no-repeat scroll 0 0 transparent;

        width: 5.5em;
        height: 5.5em;
        margin-top: 0.1em;
        margin-left: -2.8em;

        @media screen and (max-width: 1023px) {
            & {
                // max-width: 60%;
            }
        }

        cursor: pointer;

        &:hover {
            filter: brightness(1.1);
        }

        &:active {
            filter: brightness(0.9);
        }

        &:disabled {
            // background: #7c7986 !important;
            @include desaturate;
        }

        &:disabled:hover,
        &:disabled:active {
            // filter: brightness(1);
            @include desaturate;
        }
    }
}

@mixin message {
    position: relative;
    font-family: 'Cera Round Pro Black';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6em;
    line-height: 1.2em;
    letter-spacing: -0.04rem;
    display: flex;
    align-items: right;
    text-align: right;
    text-transform: uppercase;

    padding: 1em;

    color: #ffffff;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    width: 15em;
    margin: 0.6em auto 10em;

    @media screen and (max-width: 1023px) {
        & {
            margin: 0.6em auto 0.5em;
        }
    }

    color: #ffd563;
    // color: #70ff63;

    justify-content: center;
}

.success__message {
    @include message();
}

.error__message {
    @include message();
    color: red;
}