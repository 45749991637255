@media screen and (max-width: 1024px) {
  .bttnWrapper {
    font-size: 1.4em !important;

    right: 70px !important;
    transform: translate(0, -50%) !important;
  }
}
@media screen and (max-width: 768px) {
  .bttnWrapper {
    font-size: 2em !important;

    right: 70px !important;
    transform: translate(0, -50%) !important;
  }
}
@media screen and (max-width: 426px) {
  .bttnWrapper {
    font-size: 3em !important;
    right: 36% !important;
    transform: translate(50%, -50%) !important;
  }
}
@media screen and (max-width: 425px) {
  .bttnWrapper {
    font-size: 3.2em !important;
    /* right: 48% !important; */
    transform: translate(50%, -50%) !important;
  }
}
@media screen and (max-width: 375px) {
  .bttnWrapper {
    font-size: 4em !important;
    /* right: 48% !important; */
    transform: translate(50%, -50%) !important;
  }
}
@media screen and (max-width: 325px) {
  .bttnWrapper {
    font-size: 3.6em !important;
    /* right: 48% !important; */
    transform: translate(50%, -50%) !important;
  }
}
@media screen and (max-width: 280px) {
  .bttnWrapper {
    font-size: 5.8em !important;
    /* right: 48% !important; */
    transform: translate(50%, -50%) !important;
  }
}

.main {
}
.bg {
  position: fixed;
  top: 0;
  left: 0;

  height: 72px;
  width: 100%;
  // background: rgba(55, 58, 71, 1);
  background: rgba(55, 58, 71, 0.5);
  z-index: 999;
}

.warning__bg {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 72px;
  left: 0;

  height: 9em;
  width: 100vw;
  background: rgba(238, 233, 112, 0.9);
  z-index: 999;
}

.warning__text {
  width: 80%;

  font-family: 'Cera Round Pro';
  font-style: normal;
  font-weight: 100;
  font-size: 4em;
  line-height: 1em;

  text-transform: capitalize;

  color: rgb(255, 77, 0);

  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
}

.moblogo {
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translate(0, -50%);

  height: 60px;
  z-index: 999;
}
.bttnWrapper {
  position: absolute;
  top: 50%;
  right: 48%;
  transform: translate(50%, -50%);

  font-size: 3em;
}

.menu {
  /* width: ; */
  width: auto !important;
  overflow: hidden !important;
}

.burgerButton {
  position: fixed;
  width: 26px;
  height: 20px;
  right: 20px;
  top: 26px;
}

.burgerBar {
  background: white;
}

.crossButton {
  height: 20px;
  width: 20px;
}

.cross {
  background: white;
}

.morphShape {
  fill: #373a47;
}

.itemList {
  color: #b8b7ad;
  padding: 0.8em;
}

.overlay {
  background: rgba(0, 0, 0, 0.7) !important;
}
