.main {
  position: relative;
  
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100vh;
  background: url(../../resources/images/nft_items/bg.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  // -webkit-mask: linear-gradient(
  //   transparent,
  //   #fff 0px calc(100% - 1px),
  //   transparent
  // );
  // mask: linear-gradient(transparent, #fff 0px calc(100% - 1px), transparent);

  .content {
    // display: flex;

    .part1 {
      position: absolute;
      top: 50%;
      left: 9.5vw;
      transform: translate(0, -50%);
    }
    
    .part2 {
      position: absolute;
      top: 0;
      left: 43.85vw;
      
      // background: red;
      clip-path: polygon(19.8% 0%, 100% 0, 100% 100%, 0 100%);

      .sep {
        // border: solid 1px green;

        position: absolute;
        top: 0;
        left: 0;

        width: calc(19.8% + 3px);
        height: 100%;
        background: #7994a4;
        z-index: 999;

        clip-path: polygon(0% 0%, 100% 0%, 3px 100%, 0% 100%);
      }
    }
  }

}

// @media screen and (max-width: 4096px) {.roadMapProgressLineWrapper {transform: translate(-50%, 46vh) rotate(-26deg);}}
// @media screen and (max-width: 1441px) {.separator {right: 0%}}
// @media screen and (max-width: 1440px) {.roadMapProgressLineWrapper {transform: translate(-50%, 46vh) rotate(-26deg);}}
// @media screen and (max-width: 1025px) {.roadMapProgressLineWrapper {transform: translate(-50%, 46vh) rotate(-26deg);}}
// @media screen and (max-width: 1024px) {.roadMapProgressLineWrapper {transform: translate(-50%, 46vh) rotate(-26deg);}}
// @media screen and (max-width: 768px) {.roadMapProgressLineWrapper {transform: translate(-50%, 46vh) rotate(-26deg);}}
// @media screen and (max-width: 426px) {.roadMapProgressLineWrapper {transform: translate(-50%, 46vh) rotate(-26deg);}}
// @media screen and (max-width: 425px) {.roadMapProgressLineWrapper {transform: translate(-50%, 46vh) rotate(-26deg);}}
// @media screen and (max-width: 375px) {.roadMapProgressLineWrapper {transform: translate(-50%, 46vh) rotate(-26deg);}}
// @media screen and (max-width: 325px) {.roadMapProgressLineWrapper {transform: translate(-50%, 46vh) rotate(-26deg);}}

// @media screen and (max-height: 700px) {.roadMapProgressLineWrapper {transform: translate(-50%, 46vh) rotate(-26deg);}}
// @media screen and (max-height: 600px) {.roadMapProgressLineWrapper {transform: translate(-50%, 46vh) rotate(-18deg);}}
// @media screen and (max-height: 500px) {.roadMapProgressLineWrapper {transform: translate(-50%, 46vh) rotate(-22deg);}}
