.main {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);

  .content {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-right: 1.3em;

    margin-bottom: -2em;
  }

  .bttn {
    display: inline-block;
    margin: -3em auto 4em;
  }

  .arrows1 { height: 27em; }
  .arrows2 { height: 35em;}
}