.main {
  position: relative;
  
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100vh;
  background: url(../../resources/images/oasis_coin/bg.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.title {
  /* margin-top: 1em; */
  padding-top: 1.9em;

  font-family: 'Cera Round Pro Black';
  font-style: normal;
  font-weight: 700;
  font-size: 2.4em;
  line-height: 1.3em;
  text-align: center;
  letter-spacing: 0.05rem;

  background: linear-gradient(
    180deg,
    #ffc74f 60%,
    #ffe866 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-transform: uppercase;

  // background: linear-gradient(
  //   180deg,
  //   #cbf3ff -37.5%,
  //   #daf6fe 88.97%,
  //   rgba(228, 248, 254, 0.455208) 99.99%,
  //   #58d5fd 100%
  // );
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // background-clip: text;

  /* text-shadow: 0px 0.2em 0.2em rgba(0, 0, 0, 0.25); */
  z-index: 99;
}

.description {
  margin: 0 auto;
  max-width: 700px;
  font-family: 'Cera Round Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1.2em;
  text-align: center;

  color: #ffffff;

  text-shadow: 0px 0.1em 0.1em rgba(0, 0, 0, 0.25);
  z-index: 99;
}