@mixin mainTextShadow {
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.55);
}

@mixin mainBoxShadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@mixin mainFilterShadow {
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
}

@mixin desaturate {
    filter: saturate(0%);
}

@mixin retina($density: 2) {
    @media only screen and (-webkit-min-device-pixel-ratio: $density),
    only screen and (min--moz-device-pixel-ratio: $density),
    only screen and (-o-min-device-pixel-ratio: #{$density}/1),
    only screen and (min-device-pixel-ratio: $density),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: $density dppx) {
        @content;
    }
}

@mixin retina2($resolution, $density: 2) {
    @media only screen and (-webkit-min-device-pixel-ratio: $density),
    only screen and (min--moz-device-pixel-ratio: $density),
    only screen and (-o-min-device-pixel-ratio: #{$density}/1),
    only screen and (min-device-pixel-ratio: $density),
    // only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: $density dppx) {
        @media screen and (max-width: $resolution) {
            @content;
        }
    }
}