.main {
    display: flex;
    flex-direction: column;

    height: 5em;
    margin-top: 1.5em;
}
.email__lable {
    color: redwhite;
    text-align: left;
    font-size: 1.2em;
}
.email__field {
    width: 24.6em;
    height: 2.6em;
    border-radius: 0.3em;

    font-family: 'Cera Round Pro';
    font-style: normal;
    font-size: 1.2em;
    text-align: left;
    letter-spacing: -0.068rem;

    color: rgba(0, 0, 0, 0.8);
    padding-left: 1em;

    &::placeholder {
        // font-family: 'Cera Round Pro';
        // font-style: normal;
        // font-size: 1.2em;
        // text-align: left;
        // letter-spacing: -0.068rem;

        color: rgba(0, 0, 0, 0.38);
    }
}

.email__field.error_input {
    // border: solid 1px red;
}
.email__field.warning_input {
    // border: solid 1px orange;
}

.error {
    font-family: 'Cera Round Pro';
    font-style: normal;
    font-size: small;
    color: red;
    text-align: left;

    padding-top: 2px;
}
.warning {
    font-family: 'Cera Round Pro';
    font-style: normal;
    font-size: small;
    color: red;
    text-align: left;

    padding-top: 2px;
}