@import '../../../mixines.scss';

// @media screen and (max-width: 920px) {.nav__item {font-size: 2em !important;}}
// @media screen and (max-width: 768px) {.nav__item {font-size: 3.5em !important;}}
// @media screen and (max-width: 426px) {.nav__item {font-size: 4.5em !important;}}
// @media screen and (max-width: 425px) {.nav__item {font-size: 4.5em !important;}}
// @media screen and (max-width: 375px) {.nav__item {font-size: 4.5em !important;}}
// @media screen and (max-width: 325px) {.nav__item {font-size: 4.5em !important;}}
// @media screen and (max-width: 280px) {.nav__item {font-size: 6.5em !important;}}


.main {
    white-space: pre;
    .content {
        display: flex;
        align-items: center;
        .title {
            color: white;
            font-family: 'Cera Round Pro';

            @include mainTextShadow;
        }
        .itemsCont {
            display: flex;
            color: white;

            line-height: 1.6em;
            div {
                a{
                    color: #5ec2ff
                }
            }
        }
    }
}