.main {
    position: relative;
  
    width: 100vw;
    height: 100vh;
    /* background: url(../../resources/images/earlybirds/bg.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  
    width: 100vw;
    height: 100vh;
  
    background: #27284f;
  }

  .text {
    margin-top: 0.6em;
    font-family: 'Cera Round Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 1.9em;
    line-height: 1.4em;
    text-align: center;
    letter-spacing: -0.01rem;
    text-transform: uppercase;
  
    background: linear-gradient(
      180deg,
      #cbf3ff -37.5%,
      #daf6fe 28.39%,
      #58d5fd 65.62%,
      rgba(228, 248, 254, 0.455208) 99.99%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }