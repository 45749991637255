.main {
  
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  // width: 11em;
  font-family: 'Cera Round Pro Black';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 0.97em;
  text-transform: uppercase;

  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  // white-space:nowrap;
}

.title.left {
  padding: 0 0.42em 0.1em 0;
}

.title.right {
  margin-top: -0.5em;
  padding: 0 0.1em 0 0.42em;
}

.itemCont {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 11.4em;
  height: 11.4em;

  background: url('../../../../resources/images/oasis_gem/item_backing.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .icon {
      // width: 11.4em;
      width: 10em;
  }
}