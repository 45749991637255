.main {
  // position: absolute;
  // top: 18.6em;
  // left: 43.6em;

  display: inline-flex;
  justify-content: space-between;
  align-items: flex-start;

  /* border: solid 1px red; */
}