.main {
  margin: 2em 0em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .img {
    height: 11em;
    // margin: 0 auto;

    z-index: 9;
  }
  .content {

    margin-top: -5.5em;

    width: 23.2em;
    height: 24.5em;

    border-radius: 0.5em;
    border: solid 3px #bef5ff;
    box-shadow: 0px 0px 20px 0.1px #bef5ff, 0px 0px 10px 0.3px #bef5ff inset;

    .name {
      margin-top: 3.7em;
      font-family: 'Cera Round Pro Black';
      font-style: normal;
      font-weight: 700;
      font-size: 1.8em;
      line-height: 1em;
      text-align: center;
      letter-spacing: -0.05rem;

      background: linear-gradient(
        180deg,
        #ffc74f 60%,
        #ffe866 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-transform: uppercase;
    }
    .desc {
      font-family: 'Cera Round Pro Black';
      font-style: normal;
      font-weight: 700;
      font-size: 1.4em;
      line-height: 1em;
      text-align: center;
      letter-spacing: -0.05rem;

      background: linear-gradient(
        180deg,
        #ffc74f 60%,
        #ffe866 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-transform: uppercase;

      padding-top: 0.6em;
    }
    .skills {
      margin-top: 1.5em;
      padding-right: 1em;
    }
  }
}