@import '../mixines.scss';

.main {
  padding: 0 0.5em;
}

.bttn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0px;
  font-weight: bolder;
  font-family: 'Cera Round Pro';
  font-size: 18px;
  line-height: 24px;
  color: #fff;

  clip-path: polygon(0% 0, 100% 0, 100% 20%, 97% 30%, 100% 40%, 100% 100%, 0% 100%, 0 75%);
  border-radius: 0.25em;

  @include mainTextShadow;
  background: linear-gradient(to top, #09b6fe, #2acefd)  !important;

  cursor: pointer;
  height: 2em !important;
  padding: 1.15em 0.8em;
  transition: 0.3s;

  font-family: 'Cera Round Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 1em;
  display: flex;
  align-items: center;
  text-align: center;

  color: white;

  .icon {
    padding: 0.25em 0.5em 0 0;
  }
}

.bttn:hover {
  filter: brightness(1.1);
}

.bttn:active {
  filter: brightness(0.8);
}

.title {
  text-decoration: none;
  font-weight: bold;
  font-size: 1.225em;
  line-height: 1.33;
  color: #fff;

  @include mainTextShadow;
}

.bttnWrap {
  @include mainFilterShadow;
}

.link {
  text-decoration: none;
}