.main {
  position: relative;
  
  display: flex;
  align-items: center;
  flex-direction: column;

  // width: 100%;
  // height: 100vh;
  // background: url(../../resources/images/nft_items/bg.jpg);
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-size: cover;

  .content {
    width: 20.1em;
    height: 18.5em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: #333251;
    box-shadow: 0px 0px 20px 0.1px #60baff, 0px 0px 10px 0.3px #73c9ff inset;

    border: 3px solid #bef5ff;
    border-radius: 0.5em;
    .icon {
      height: 25em;
      // border: 1px solid green;
    }

    .priceCont {
      position: absolute;
      bottom: 11%;
      left: 50%;
      transform: translate(-50%, 0);

      display: flex;

      font-family: 'Cera Round Pro Black';
      font-style: normal;
      font-weight: 500;
      font-size: 1.58em;
      line-height: 1em;
      white-space: pre;
    
      color: #ffffff;
    
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}

.progressWrap {
  /* width: 20em; */
  margin-top: 3.8em;
}

.progressBg {
  width: 35.5em;
  height: 4.85em;

  background: linear-gradient(90deg, #8cd134, #4e9c23)  !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.progressContent {
  height: 100%;
  width: 93%;

  margin: 0 auto;

  /* display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.progressCountersLine {
  display: flex;
  justify-content: space-between;

  font-family: 'Cera Round Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 1em;
  letter-spacing: -0.04rem;
  text-transform: uppercase;

  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  padding: 0.8em 0.6em 0.5em 0.2em;
}

.progress__line {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress__bg {
  width: 97%;
  height: 14px;

  // background: rgba(92, 77, 77, 0.55);
  background: rgba(80, 149, 32, 1);
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}

.progress__bar {
  width: 0%;
  height: 14px;

  background: linear-gradient(180deg, #d6b519, #c18f08)  !important;
  box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}